<template>
	<div class="mt-50 cartel-main ma-auto">
		<div class="mt-50 flex-row justify-space-between">
			<CartelList
				:user="user"
				class="box-side mr-30"
			></CartelList>
			
			<cartel_bbs_list
				:user="user"
				:items_list="list_bbs"
				:max="max"
				:is_add="is_add"
				:item_search="item_search.post"

				@change="getSearchData"

				class="box-items-list mr-30"
			></cartel_bbs_list>

			<CartelEtc
				:user="user"
			></CartelEtc>
		</div>
	</div>
</template>
<script>

import CartelEtc from "@/view/Cartel/CartelEtc";
import cartel_bbs_list from "@/view/Cartel/CartelBbsList";
import CartelList from "@/view/Cartel/CartelLis";

export default {
	name: 'CartelHome'
	, components: {CartelList, cartel_bbs_list, CartelEtc}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'launchpad'
				, title: '카르텔 목록'
				, type: 'launchpad'
				, bg_contents: 'bg-gray01'
				, not_footer: true
				, banner: true
			}
			, items_my_cartel: []
			, items_bbs: []
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
				, post: {
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				}
			}
			, max: false
			, is_add: true
			, item_slide: {
				join: {
					d: 930
					, p: 1
					, tp: 1
					, doing: false
				}
				, recomm: {
					d: 930
					, p: 1
					, tp: 1
					, doing: false
				}
			}
			, mode: ''
			, is_more: false
			, item_more: {}
			, is_item_modify: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_detail: {

			}
		}
	}
	, computed: {
		is_create: function(){
			let t = false

			if(this.cartl_open_possible_fg == 'Y'){
				t = true
			}

			return t
		}

		, is_on: function(){
			let t = {
				recomm_left: false
				, recomm_right: true
				, join_left: false
				, join_right: true
			}

			if(this.item_slide.join.p > 1){
				t.join_left = true
			}else{
				t.join_left = false
			}
			if(this.item_slide.join.tp == 1 || this.item_slide.join.p == this.item_slide.join.tp){
				t.join_right = false
			}else{
				t.join_right = true
			}

			if(this.item_slide.recomm.p > 1){
				t.recomm_left = true
			}else{
				t.recomm_left = false
			}
			if(this.item_slide.recomm.tp == 1 || this.item_slide.recomm.p == this.item_slide.recomm.tp){
				t.recomm_right = false
			}else{
				t.recomm_right = true
			}

			return t
		}
		, list_bbs: function(){

			return this.items_bbs.filter((item) => {
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}else{
					item.is_block = false
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = this.$date.getTimeStory(item.registdate)
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				this.is_add = false
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_main_info
					, data: {
						member_number: this.user.member_number
						, cartl_list_page: {
							page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, nrt_post_list_page: {
							page_number: this.item_search.post.page_number
							, pagerecnum: this.item_search.post.pagerecnum
						}
					}
					, type: true
				})

				if(result.success){
					this.items_my_cartel = result.data.member_cartl_list

					if(this.item_search.post.page_number == 1){
						this.items_bbs = result.data.entry_cartl_nrt_post_list
					}else{
						this.items_bbs = this.items_bbs.concat(result.data.entry_cartl_nrt_post_list)
					}

					if(result.data.entry_cartl_nrt_post_list.length > 0){
						this.max =false
					}else{
						this.max = true
					}

					setTimeout( () => {
						let type = 'recomm'
						let t = this.$refs["box-" + type]?.scrollWidth

						this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)
					}, 100)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, getSearchData: function(page){
			if(page){
				this.item_search.post.page_number = page
			}

			this.getData()
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'detail', child: 'detail', params: {idx: item.cartl_number, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, toCartel: function(item){
			this.$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number }})
		}
		, toRight: function(type) {

			if (!this.item_slide[type].doing) {

				this.item_slide[type].doing = true

				if (this.item_slide[type].p >= this.item_slide[type].tp) {
					this.item_slide[type].p = 0
				}

				let x = this.item_slide[type].d * this.item_slide[type].p

				this.$refs["box-" + type].scrollTo({
					left: x + (25 * this.item_slide[type].p), behavior: 'smooth'
				})

				this.item_slide[type].p++
				this.item_slide[type].doing = false

			}
		}

		, toLeft: function(type){

			if(!this.item_slide[type].doing){

				this.item_slide[type].doing = true

				let t = this.$refs["box-" + type].scrollWidth

				this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)

				this.item_slide[type].p--

				if(this.item_slide[type].p <= 0){
					this.item_slide[type].p = this.item_slide[type].tp
				}
				let x = this.item_slide[type].d * this.item_slide[type].p - this.item_slide[type].d + (25 * this.item_slide[type].p - 25)

				this.$refs["box-" + type].scrollTo({
					left: x, behavior: 'smooth'
				})

				this.item_slide[type].doing = false
			}
		}
		, toCartelList: function(type){

			this.mode = type

			if(type == 'total'){
				this.$bus.$emit('to', { path: this.$route.path + '#total'})
			}else{
				this.$bus.$emit('to', { name: 'main'})
			}
		}
		, getCartelMain: function(){
			if((this.$route.fullPath).indexOf('#total') > -1){
				this.mode = 'total'
			}else if((this.$route.fullPath).indexOf('#search') > -1) {
				this.mode = 'search'
			}else{
				this.mode = ''
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_bbs.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			this.is_delete = false
			await this.mount()
		}
		, onModify: function(){
			this.is_item_modify = true
		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$log.console('044 user', this.user)
		this.$emit('onLoad', this.program)
		this.getCartelMain()
		await this.getData()
	}
	, watch:{
		$route(to){

			this.mode = ''
			if(to.hash){
				this.getCartelMain()
				this.getData()
			}
		}
	}
}
</script>

<style>
</style>